<template>
  <div class="h-112">
    <apex-chart
      class="mx-auto lg:mb-2"
      type="line"
      height="100%"
      :options="chartOptions"
      :series="props.series"
    />
  </div>
  <p class="text-xs text-blue-gray-500">
    Contains data from
    <a
      href="https://data.gov.sg/dataset/graduate-employment-survey-ntu-nus-sit-smu-suss-sutd"
      target="_blank"
      rel="noopener noreferrer"
      class="underline sm:hover:text-sky-800"
      >Graduate Employment Survey</a
    >
    from
    <a
      href="https://www.moe.gov.sg/"
      target="_blank"
      rel="noopener noreferrer"
      class="underline sm:hover:text-sky-800"
      >MOE</a
    >
    made available under
    <a
      href="https://data.gov.sg/open-data-licence"
      target="_blank"
      rel="noopener noreferrer"
      class="underline sm:hover:text-sky-800"
      >Singapore Open Data Licence v1.0</a
    >
  </p>
</template>

<script setup>
import { toRefs, computed } from "vue";
import ApexChart from "vue3-apexcharts";

const props = defineProps({
  // Series as an array as we want multiple series at the same time
  series: {
    type: Array,
    required: true,
  },
  maxSelect: {
    type: Number,
    required: true,
  },
  statisticsDefault: {
    type: Boolean,
    required: true,
  },
});

const commonChartOptions = {
  stroke: {
    width: 3,
    lineCap: "round",
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontWeight: 500,
        fontSize: "0.625rem",
      },
      format: "yyyy",
    },
    tooltip: {
      enabled: false,
    },
  },
  legend: {
    fontWeight: 500,
    showForSingleSeries: true,
    showForNullSeries: false,
  },
  markers: {
    size: 3,
    strokeWidth: 0,
    hover: {
      sizeOffset: 1,
    },
  },
  noData: {
    text: `Choose up to ${props.maxSelect} courses below`,
    style: {
      fontSize: "0.875rem",
    },
  },
  subtitle: {
    text: "Made with SGUniStats (sgunistats.com)",
    offsetY: 24,
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#9699a2",
    },
  },
};

const medianOptions = {
  chart: {
    id: "unistats-median-ges",
    toolbar: { show: false },
    fontFamily:
      "Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    foreColor: "#1F2A38",
    zoom: {
      enabled: false,
    },
  },
  yaxis: {
    forceNiceScale: true,
    max: function (max) {
      // Rounding to lower 100 then adding 200
      return Math.floor(max / 100) * 100 + 200;
    },
    labels: {
      formatter: function (value) {
        return `$${Math.round(value / 100) / 10}k`;
      },
      style: {
        fontWeight: 500,
        fontSize: "0.625rem",
      },
    },
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
    y: {
      formatter: function (value) {
        if (value > 1000)
          return `$${value.toString()[0]},${value.toString().slice(1)}`;
        return "$" + value;
      },
    },
  },
  title: {
    text: "GES Median Income",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#263238",
    },
  },
};

const employmentOptions = {
  chart: {
    id: "unistats-employment-ges",
    toolbar: { show: false },
    fontFamily:
      "Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    foreColor: "#1F2A38",
    zoom: {
      enabled: false,
    },
  },
  yaxis: {
    min: function (min) {
      // Rounding to higher 5% then subtracting 10%
      return min < 0.1 ? 0 : Math.ceil(min * 20) / 20 - 0.1;
    },
    max: function (max) {
      // Rounding to lower 5% then adding 5%
      return max > 0.9 ? 1 : Math.floor(max * 20) / 20 + 0.05;
      // return 1;
    },
    labels: {
      formatter: function (value) {
        return `${Math.round(value * 100)}%`;
      },
    },
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
    y: {
      formatter: function (value) {
        return `${Math.round(value * 100)}%`;
      },
    },
  },
  title: {
    text: "GES Employment Rate",
  },
};

const { statisticsDefault } = toRefs(props);

const chartOptions = computed(() =>
  statisticsDefault.value
    ? { ...commonChartOptions, ...medianOptions }
    : { ...commonChartOptions, ...employmentOptions }
);
</script>

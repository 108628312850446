<template>
  <div>
    <p class="font-bold">{{ props.toggleLabel }}</p>
    <div
      class="
        flex
        text-sm
        font-medium
        text-center
        sm:text-base
        text-blue-gray-600
        w-72
        sm:w-80
      "
    >
      <div
        class="
          flex-1
          px-2
          py-1
          transition
          duration-200
          border-t-2 border-b-2 border-l-2
          cursor-pointer
          rounded-l-lg
          border-blue-gray-400
        "
        :class="{
          'bg-sky-50 border-sky-700 border-r-2': props.toggleState,
        }"
        @click="updateToggle(true)"
      >
        <p
          :class="{
            'text-sky-700 font-semibold': props.toggleState,
          }"
        >
          {{ props.defaultValue }}
        </p>
      </div>
      <div
        class="
          flex-1
          px-2
          py-1
          transition
          duration-200
          border-t-2 border-b-2 border-r-2
          cursor-pointer
          rounded-r-lg
          border-blue-gray-400
        "
        :class="{
          'bg-sky-50 border-sky-700 border-l-2': !props.toggleState,
        }"
        @click="updateToggle(false)"
      >
        <p
          :class="{
            'text-sky-700 font-semibold': !props.toggleState,
          }"
        >
          {{ props.otherValue }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  toggleState: {
    type: Boolean,
    required: true,
  },
  toggleLabel: {
    type: String,
    required: true,
  },
  defaultValue: {
    type: String,
    required: true,
  },
  otherValue: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["update:toggleState"]);

const updateToggle = (updatedToggle) =>
  emits("update:toggleState", updatedToggle);
</script>

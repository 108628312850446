<template>
  <main>
    <!-- Prospects Header -->
    <the-heading background-image="city">
      <template #header>
        <p
          class="
            mb-2
            text-sm
            tracking-wider
            md:text-base
            xl:text-lg
            text-blue-gray-50
          "
        >
          SGUniStats | Prospects
        </p>
        <h1
          class="
            text-2xl
            font-semibold
            leading-7
            md:text-3xl
            xl:text-4xl
            text-blue-gray-50
          "
        >
          Compare employment trends between&nbsp;courses
        </h1>
      </template>
    </the-heading>

    <section class="container px-3 pt-4 pb-4 mx-auto sm:px-0 sm:pt-8">
      <div class="flex items-center gap-2 mb-2 text-blue-gray-800">
        <h2 class="text-2xl font-bold">Job Prospects</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="128"
            y1="24"
            x2="128"
            y2="232"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <path
            d="M184,88a40,40,0,0,0-40-40H108a40,40,0,0,0,0,80h44a40,40,0,0,1,0,80H104a40,40,0,0,1-40-40"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></path>
        </svg>
      </div>
      <h3 class="mb-4 font-medium leading-5">
        Find out how fresh graduates are paid depending on their major
      </h3>

      <app-chart-line
        :series="series"
        :max-select="maxSelect"
        :statistics-default="statisticsDefault"
      />
    </section>
    <!-- Multiselect and Filter -->
    <section class="container px-3 pt-2 pb-16 mx-auto sm:px-0">
      <h2 class="mb-1 text-xl font-bold text-blue-gray-800">
        University Courses
      </h2>
      <h3 class="mb-4 font-medium leading-5">
        Compare median incomes and employment rates between courses
      </h3>
      <multiselect
        v-model="selected"
        :options="selectOptions"
        mode="tags"
        valueProp="id"
        trackBy="searchTerms"
        :searchable="true"
        :closeOnSelect="false"
        :max="maxSelect"
        :placeholder="`Search up to ${maxSelect} courses`"
        noResultsText="No courses with available data found."
        class="mb-6"
        :classes="{
          container:
            'transition-shadow duration-200 relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-blue-gray-300 rounded bg-white text-base font-sans leading-snug outline-none',
          containerOpen: 'rounded-b-none',
          containerActive: 'ring ring-blue-500 ring-opacity-30',
          search:
            'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
          tag: 'bg-blue-600 text-blue-50 text-xs font-semibold py-0.5 pl-2 rounded-full mr-1 mb-1 flex items-center',
          tagRemove:
            'flex items-center justify-center p-1 mx-0.5 rounded-lg sm:hover:bg-black sm:hover:bg-opacity-10 group',
          tagRemoveIcon:
            'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper:
            'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch:
            'absolute inset-0 border-0 outline-none appearance-none p-0 text-base box-border w-full',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder:
            'flex text-sm sm:text-base items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-blue-gray-600',
          caret:
            'bg-multiselect-caret bg-center bg-no-repeat w-4 h-6 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
          caretOpen: 'rotate-180 pointer-events-auto',
          clear:
            'mr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex sm:hover:opacity-80',
          clearIcon:
            'bg-multiselect-remove bg-center bg-no-repeat w-4 h-6 py-px box-content inline-block',
          dropdown:
            'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-blue-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          option:
            'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-blue-gray-800 bg-blue-gray-100',
          optionSelected: 'text-white bg-blue-500',
          optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
          noOptions: 'py-2 px-3 text-blue-gray-600 bg-white',
          noResults: 'py-2 px-3 text-blue-gray-600 bg-white',
          spacer: 'h-9 py-px box-content',
        }"
        style="caret-color: #0284c7"
      >
        <template #placeholder>
          <div
            class="
              flex
              items-center
              h-full
              absolute
              left-0
              top-0
              pointer-events-none
              bg-transparent
              leading-snug
              pl-2.5
              text-blue-gray-600
              gap-1
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <circle
                cx="115.99707"
                cy="116"
                r="84"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></circle>
              <line
                x1="175.39063"
                y1="175.40039"
                x2="223.99121"
                y2="224.00098"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></line>
            </svg>
            <p class="text-sm sm:text-base">
              Search up to {{ maxSelect }} courses
            </p>
          </div>
        </template>

        <template #option="{ option }">
          <span class="mr-1 text-sm">{{ option.major }}</span>
          <span
            class="text-xs font-medium"
            :class="{
              'text-red-700': option.university === 'NUS',
              'text-sky-700': option.university === 'SMU',
              'text-green-700': option.university === 'NTU',
              'text-blue-700': option.university === 'SUTD',
              'text-purple-700': option.university === 'SIT',
              'text-pink-700': option.university === 'SUSS',
            }"
            v-if="option.university"
            >({{ option.university }})</span
          >
        </template>
      </multiselect>
      <app-toggle
        v-model:toggleState="statisticsDefault"
        toggleLabel="Filter"
        defaultValue="Median Incomes"
        otherValue="Employment Rate"
      />
    </section>

    <app-hr />

    <app-list-ges v-once />

    <app-hr />

    <app-cards :hiddenCards="['prospects']" desc="More Tools" />

    <app-hr />

    <app-notes page="prospects" />
  </main>
</template>

<script setup>
import { ref, computed } from "vue";

import Multiselect from "@vueform/multiselect";
import TheHeading from "@/components/TheHeading.vue";
import AppChartLine from "@/components/AppChartLine.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppListGes from "@/components/AppListGes.vue";
import AppNotes from "@/components/AppNotes.vue";
import AppCards from "@/components/AppCards.vue";
import AppHr from "@/components/AppHr.vue";

import medianGES from "@/data/medianGES.json";
import employmentGES from "@/data/employmentGES.json";
import courses from "@/data/courses.json";
import { hasGES } from "@/data/courseLists.json";

const selectOptions = hasGES
  .map((id) => {
    return {
      id,
      label: `${courses[id].major} (${courses[id].university})`,
      searchTerms: `${courses[id].major} ${courses[id].university} ${courses[id].major}`,
      major: courses[id].major,
      university: courses[id].university,
    };
  })
  .sort((a, b) => a.major.localeCompare(b.major));
const maxSelect = 5;

const selected = ref([]);
const statisticsDefault = ref(true);

const series = computed(() =>
  statisticsDefault.value
    ? selected.value.map((id) => {
        // Converting the year from string to number
        return {
          name: `${courses[id].major} (${courses[id].university})`,
          data: medianGES[id],
        };
      })
    : selected.value.map((id) => {
        // Converting the year from string to number
        return {
          name: `${courses[id].major} (${courses[id].university})`,
          data: employmentGES[id],
        };
      })
);
</script>
